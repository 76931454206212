import React from "react";
import styles from "./HowItWorks.module.css";

const HowItWorks = ({ webPilotAPI }) => {
  return (
    <>
      <section className={styles.howItWorksSection}>
        <div className={styles.container}>
          <h2 className={styles.title}>How It Works</h2>
          <div className={styles.stepsContainer}>
            <div className={styles.step}>
              <div className={styles.stepNumber}>1</div>
              <h3 className={styles.stepTitle}>
                Enter your email and device name
              </h3>
              <p className={styles.stepDescription}>
                Enter your email address and select the device you are
                considering, e.g. <i>iPhone 16 Pro Max</i>.
              </p>
            </div>
            <div className={styles.step}>
              <div className={styles.stepNumber}>2</div>
              <h3 className={styles.stepTitle}>
                {webPilotAPI && <span>Select the report </span>}and accept the
                terms
              </h3>
              <p className={styles.stepDescription}>
                {webPilotAPI && (
                  <span>Select the report type: basic or extended.</span>
                )}{" "}
                Then accept the terms and conditions.
              </p>
            </div>
            <div className={styles.step}>
              <div className={styles.stepNumber}>3</div>
              <h3 className={styles.stepTitle}>Get Your Recommendation</h3>
              <p className={styles.stepDescription}>
                Click "Get Recommendation". The system will generate a free
                report for the device.
              </p>
            </div>
            <div className={styles.step}>
              <div className={styles.stepNumber}>4</div>
              <h3 className={styles.stepTitle}>What you’ll get</h3>
              <p className={styles.stepDescription}>
                Receive a tailored report with pros, cons, user reviews,
                alternatives, and more to help you decide.
              </p>
            </div>
          </div>
          <p className={styles.footerText}>Fast, simple, and effective!</p>
        </div>
      </section>
    </>
  );
};

export default HowItWorks;
