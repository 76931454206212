import React, { useEffect, useState } from "react";
import styles from "./Partner.module.css";
import Footer from "../Footer/Footer";
import Spinner from "../../common/Other/Spinner";
import report from "../../../assets/files/report_example.pdf";
import emailImage from "../../../assets/files/email_example.webp";
import instagramImage from "../../../assets/files/instagram_example.webp";
import websiteImage from "../../../assets/files/website_example.webp";
import blogImage from "../../../assets/files/blog_example.webp";

/**
 * Partner component represents the page where potential partners can learn
 * about the benefits of joining the partnership program, available advertising packages,
 * and how to contact the company for collaboration.
 *
 * @component
 * @example
 * return (
 *   <Partner />
 * )
 */
const Partner = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div className={styles.spinnerContainer}>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className={styles.partnerContainer}>
        {/* SECTION: RECOMMENDATIONS ARE INDEPENDENT */}
        <section className={styles.independentRecommendationsSection}>
          <h2>Our ethic</h2>
          <p>
            Our reports and recommendations are based on professional AI
            analyses and do not depend on purchased advertising packages. We
            maintain full objectivity and transparency to provide reliable
            opinions and help users make informed decisions. You can become our
            partner, promote your products and gain greater visibility, but
            remember that the principle of independence of reviews is very
            important to us.
          </p>
        </section>

        {/* SECTION: WHY BECOME A PARTNER */}
        <section className={styles.whyBecomePartnerSection}>
          <h2>Why become a Partner?</h2>
          <ul>
            <li>Reach a broad audience of users interested in your product.</li>
            <li>Increase credibility with advanced AI-based analysis.</li>
            <li>
              Promote your products in reports and hardware testing articles.
            </li>
            <li>
              Unique opportunities for positioning and increasing brand
              visibility.
            </li>
          </ul>
        </section>

        {/* SECTION: OUR PACKAGES */}
        <section className={styles.ourPackagesSection}>
          <h2>Our advertising packages</h2>
          <p>
            We offer flexible promotion options to help increase your brand's
            reach. Contact us to learn more.
          </p>

          <div className={styles.packagesContainer}>
            <div className={styles.package}>
              <h3>Basic Package</h3>
              <p>Advertisement in reports and hardware testing articles.</p>
            </div>

            <div className={styles.package}>
              <h3>Premium Package</h3>
              <p>
                Advertisement in reports, hardware testing articles, and
                featured promotion on the website.
              </p>
            </div>

            <div className={styles.package}>
              <h3>Exclusive Package</h3>
              <p>
                Advertisement in reports and articles, instagram posts, and
                dedicated promotional materials on the website.
              </p>
            </div>
          </div>
        </section>

        {/* SECTION: ADVERTISING MATERIALS */}
        <section className={styles.contactSection}>
          <h2>Advertising Materials</h2>
          <p>
            Below are examples of places where we can place your advertising
            materials.
          </p>
          <div className={styles.packagesContainer}>
            <div className={styles.packageMaterials}>
              <a
                href={report}
                download="report_example.pdf"
                className={styles.materialLink}
              >
                <h4>File: report - example</h4>
              </a>
            </div>
            <div className={styles.packageMaterials}>
              <a
                href={emailImage}
                download="email_example.webp"
                className={styles.materialLink}
              >
                <h4>File: email - example</h4>
              </a>
            </div>
            <div className={styles.packageMaterials}>
              <a
                href={instagramImage}
                download="instagram_example.webp"
                className={styles.materialLink}
              >
                <h4>File: instagram - example</h4>
              </a>
            </div>
            <div className={styles.packageMaterials}>
              <a
                href={websiteImage}
                download="website_example.webp"
                className={styles.materialLink}
              >
                <h4>File: website - example</h4>
              </a>
            </div>
            <div className={styles.packageMaterials}>
              <a
                href={blogImage}
                download="blog_example.webp"
                className={styles.materialLink}
              >
                <h4>File: blog - example</h4>
              </a>
            </div>
          </div>
        </section>

        {/* SECTION: CONTACT */}
        <section className={styles.contactSection}>
          <h2>Contact Us</h2>
          <p>
            Do you have questions or want to learn more about our advertising
            packages? Write to us and we'll provide a personalized offer.
          </p>
          <a
            href="mailto:partner@picklynow.com"
            className={styles.contactButton}
          >
            Contact Us
          </a>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Partner;
