import React from "react";
import { Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import HomePage from "./pages/Home/HomePage";
import Success from "./components/picklynow/Status/Success";
import Cancel from "./components/picklynow/Status/Cancel";
import NavBar from "./components/common/Navigation/NavBar";
import Article from "./components/picklynow/Blog/Article";
import Partner from "./components/picklynow/Partner/Partner";
import BasicReviewStatus from "./components/picklynow/Status/BasicReviewStatus";
import Survey from "./components/picklynow/Survey/Survey";
import NotFoundPage from "./pages/Other/NotFoundPage";
import useAnalytics from "./utils/useAnalytics";
import styles from "./App.module.css";
import "./global.css";

/**
 * The main component of the application.
 *
 * @returns {JSX.Element} The rendered App component.
 */
const App = () => {
  useAnalytics();

  return (
    <div className={styles.container}>
      <Helmet>
        <title>Home - Picklynow</title>
        <meta
          name="description"
          content="Welcome to Picklynow! Get expert advice and personalized tech recommendations on the latest gadgets and accessories."
        />
        <meta
          name="keywords"
          content="tech recommendations, personalized advice, gadgets, electronics, product reviews, tech support"
        />
        <meta
          property="og:title"
          content="Picklynow - Expert Tech Recommendations"
        />
        <meta
          property="og:description"
          content="Welcome to Picklynow! Get expert advice and personalized tech recommendations on the latest gadgets and accessories."
        />
        <meta property="og:url" content="https://www.picklynow.com" />
      </Helmet>
      <div className={styles.content}>
        <NavBar />
        <div className="content">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/success/:transactionID" element={<Success />} />
            <Route path="/cancel" element={<Cancel />} />
            <Route path="/article/:id" element={<Article />} />
            <Route path="/partner" element={<Partner />} />
            <Route path="/status" element={<BasicReviewStatus />} />
            <Route path="/survey/:rating/:survey" element={<Survey />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default App;
