import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DOMPurify from "dompurify";
import { FaRegGrinStars } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { IoPaperPlaneOutline } from "react-icons/io5";
import Spinner from "../../common/Other/Spinner";
import ErrorMessage from "../../common/Message/ErrorMessage";
import termsAndConditions from "../../../assets/files/terms_and_conditions_pickly_2024.pdf";
import imageHeader from "../../../assets/images/four.webp";
import countries from "../../../utils/countries";
import styles from "./Review.module.css";

const Review = ({ reportError, openAIAPI, webPilotAPI, errorStatus }) => {
  const [email, setEmail] = useState("");
  const [model, setModel] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [reportType, setReportType] = useState("basic");
  const [country, setCountry] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [emailError, setEmailError] = useState("");
  const [modelError, setModelError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const preloadImage = new Image();
    preloadImage.src = imageHeader;
    preloadImage.onload = () => setImageLoaded(true);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (!termsAccepted) {
      alert("You must accept the terms and conditions.");
      return;
    }

    let valid = true;
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      valid = false;
    } else {
      setEmailError("");
    }

    if (model.trim() === "") {
      setModelError("You must enter a device model (e.g., iPhone 16 Pro Max).");
      valid = false;
    } else {
      setModelError("");
    }

    if (!country) {
      setCountryError(
        "You must enter a device model (e.g., iPhone 16 Pro Max)."
      );
      valid = false;
    } else {
      setCountryError("");
    }

    if (!valid) {
      return;
    }

    const sanitizedEmail = DOMPurify.sanitize(email);
    const sanitizedModel = DOMPurify.sanitize(model);
    const sanitizedReportType = DOMPurify.sanitize(reportType);
    const sanitizedCountry = DOMPurify.sanitize(country);

    try {
      if (reportType === "basic") {
        axios.post(
          `${process.env.REACT_APP_API_URL}/review/create-basic-review`,
          {
            email: sanitizedEmail,
            model: sanitizedModel,
            termsAccepted,
            reportType: sanitizedReportType,
            country: sanitizedCountry,
          }
        );
        setTimeout(() => {
          navigate("/status", { replace: true });
          window.location.reload();
        }, 3000);
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/payments/create-checkout-session`,
          {
            email: sanitizedEmail,
            model: sanitizedModel,
            termsAccepted,
            reportType: sanitizedReportType,
          }
        );

        if (response.status === 201) {
          if (response.data.url) {
            window.location.href = response.data.url;
          }
        }
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.status);
      } else {
        setError("Error occurred. Please try again.");
      }
      Sentry.captureException(error, {
        extra: {
          message: "Error while creating new review",
          component: "Review",
        },
      });
    }
  };

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailPattern.test(email);
  };

  const isFormValid =
    email.trim() !== "" &&
    model.trim() !== "" &&
    termsAccepted &&
    !emailError &&
    !modelError &&
    country;

  return (
    <div className={styles.box}>
      <div className={styles.container}>
        <div className={styles.headerBox}>
          <div className={styles.headerLeft}>
            <h1 className={styles.header}>Get the Best Device for You</h1>
            <p className={styles.paragraph}>
              Get personalized advice for your next device! Enter your email
              address and product name to receive AI-powered recommendations for
              free.
            </p>
            <div className={styles.summary}>
              <p className={styles.summaryItem}>
                <span>
                  <FaRegGrinStars />
                </span>
                4.9 user rating
              </p>
              <p className={styles.summaryItem}>
                <span>
                  <FiUsers />
                </span>
                800+ users
              </p>
              <p className={styles.summaryItem}>
                <span>
                  <IoPaperPlaneOutline />
                </span>
                2000+ recommendations
              </p>
            </div>
            <div>
              <button
                onClick={() => {
                  const formElement = document.getElementById(
                    "recommendation-form"
                  );
                  const isMobile = window.innerWidth <= 768;

                  if (isMobile) {
                    const offset = -100;
                    window.scrollTo({
                      top: formElement.offsetTop + offset,
                      behavior: "smooth",
                    });
                  } else {
                    formElement.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  }
                }}
                className={styles.scrollButton}
              >
                Get Your Free Recommendation
              </button>
            </div>
          </div>
          <div className={styles.headerRight}>
            {imageLoaded ? (
              <img
                src={imageHeader}
                alt="Description of the image"
                className={styles.imageHeader}
              />
            ) : (
              <div className={styles.imagePlaceholder}>Loading image...</div>
            )}
          </div>
        </div>

        {error && <ErrorMessage statusCode={error} message={error} />}

        {reportError && openAIAPI && !errorStatus ? (
          <form
            onSubmit={handleSubmit}
            className={`${styles.form} form-mobile`}
            id="recommendation-form"
          >
            <div className={styles.fieldsBox}>
              <div className={styles.inputGroup}>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className={styles.input}
                  placeholder="E-mail"
                />
                <span className={styles.hint}>Enter the e-mail address</span>
                {emailError && (
                  <span className={styles.error}>{emailError}</span>
                )}
              </div>
              <div className={styles.inputGroup}>
                <input
                  type="text"
                  id="model"
                  name="model"
                  value={model}
                  onChange={(e) => setModel(e.target.value)}
                  required
                  className={styles.input}
                  placeholder="Device model"
                />
                <span className={styles.hint}>
                  Enter the product name e.g. iPhone 16 Pro Max
                </span>
                {modelError && (
                  <span className={styles.error}>{modelError}</span>
                )}
              </div>

              <div className={styles.inputGroup}>
                <select
                  id="country"
                  name="country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  className={styles.input}
                >
                  <option value="">Select your country</option>
                  {countries.map((countryName, index) => (
                    <option key={index} value={countryName}>
                      {countryName}
                    </option>
                  ))}
                </select>
                <span className={styles.hint}>
                  Select country of origin e.g. United States
                </span>
                {countryError && (
                  <span className={styles.error}>{countryError}</span>
                )}
              </div>

              {webPilotAPI && (
                <div className={styles.inputGroup}>
                  <select
                    id="reportType"
                    name="reportType"
                    value={reportType}
                    onChange={(e) => setReportType(e.target.value)}
                    className={styles.input}
                  >
                    <option value="basic">Basic report - free</option>
                    <option value="extended">Extended report</option>
                  </select>
                  <span className={styles.hint}>
                    Choose a basic report for free{" "}
                    {webPilotAPI && (
                      <span>
                        or an extended report for $5 with user sentiment
                        analysis
                      </span>
                    )}
                  </span>
                </div>
              )}
            </div>
            <div className={styles.secondLine}>
              <div className={styles.checkboxGroup}>
                <input
                  type="checkbox"
                  id="terms"
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  className={styles.checkbox}
                />
                <label htmlFor="terms" className={styles.checkboxLabel}>
                  I accept the{" "}
                  <a
                    href={termsAndConditions}
                    download="terms_and_conditions_pickly_2024.pdf"
                    className={styles.decorationLink}
                  >
                    terms and conditions.
                  </a>
                </label>
              </div>
              <div>
                <button
                  type="submit"
                  className={`${styles.button} ${
                    !isFormValid ? styles.buttonDisabled : ""
                  }`}
                  disabled={!isFormValid}
                >
                  {loading ? <Spinner /> : <span>Get Your Recommendation</span>}
                </button>
              </div>
            </div>
          </form>
        ) : (
          <div className={styles.maintenance}>
            Thank you for visiting! Our website is currently undergoing
            maintenance to enhance your experience. We’ll be back online
            shortly.
          </div>
        )}
      </div>
    </div>
  );
};

export default Review;
