import React from "react";
import Footer from "../Footer/Footer";
import styles from "./SuccessAndCancel.module.css";

const Cancel = () => {
  return (
    <>
      <div>
        <div className={styles.successContainer}>
          <div className={styles.content}>
            <h1 className={styles.title}>Payment Canceled</h1>
            <p className={styles.message}>
              Your payment has been canceled. <br />
              If you believe this was a mistake, please try again or contact
              support for assistance.
            </p>
            <a href="/" className={styles.homeLink}>
              Return to Home
            </a>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Cancel;
