import React from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import {
  FaCcStripe,
  FaCcVisa,
  FaCcMastercard,
  FaInstagram,
} from "react-icons/fa";
import termsAndConditions from "../../../assets/files/terms_and_conditions_pickly_2024.pdf";
import privacyPolicy from "../../../assets/files/privacy_policy_pickly_2024.pdf";
import cookiesPolicy from "../../../assets/files/cookies_policy_pickly_2024.pdf";
import aiPolicy from "../../../assets/files/ai_policy_pickly_2024.pdf";
import logo from "../../../assets/images/logo.webp";

import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <>
      <footer className={styles.footerSection}>
        <div className={styles.container}>
          <h2 className={styles.tagline}>
            Innovation and Excellence, <br /> Every Step of the Way
          </h2>
          <div className={styles.footerContent}>
            <ul className={styles.linksList}>
              <li>
                <ScrollLink
                  to="home"
                  smooth={true}
                  duration={500}
                  className={styles.link}
                >
                  Homepage
                </ScrollLink>
              </li>
              <li>
                <a
                  href={termsAndConditions}
                  download="terms_and_conditions_pickly_2024.pdf"
                  className={styles.link}
                >
                  Terms and Conditions
                </a>
              </li>
              <li>
                <a
                  href={privacyPolicy}
                  download="privacy_policy_ikorni_2024.pdf"
                  className={styles.link}
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href={cookiesPolicy}
                  download="cookies_policy_pickly_2024.pdf"
                  className={styles.link}
                >
                  Cookies Policy
                </a>
              </li>
              <li>
                <a
                  href={aiPolicy}
                  download="ai_policy_pickly_2024.pdf"
                  className={styles.link}
                >
                  AI Policy
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.logos}>
            <Link
              to="/"
              onClick={() => window.scrollTo(0, 0)}
              className={styles.logos}
            >
              <img src={logo} alt="logo" className="logo" />
            </Link>
            <div className={styles.payLogosContainer}>
              <div className={styles.payLogos}>
                <a
                  href="https://www.mastercard.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.linka}
                  aria-label="Przejdź na stronę Mastercard"
                  title="Przejdź na stronę Mastercard"
                >
                  <FaCcMastercard size={45} />
                </a>
                <a
                  href="https://www.visa.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.linka}
                  aria-label="Przejdź na stronę Visa"
                  title="Przejdź na stronę Visa"
                >
                  <FaCcVisa size={45} alt="Visa" />
                </a>
                <a
                  href="https://www.stripe.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.linka}
                  aria-label="Przejdź na stronę Stripe"
                  title="Przejdź na stronę Stripe"
                >
                  <FaCcStripe size={45} alt="Stripe" />
                </a>
              </div>
              <div className={styles.social}>
                {/* <a
                  href={aiPolicy}
                  download="ai_policy_pickly_2024.pdf"
                  className={styles.link}
                >
                  <div className={styles.circle}>AI</div>
                </a> */}
                <a
                  href="https://www.instagram.com/picklynow/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.linka}
                  aria-label="Go to picklynow's Instagram profiles"
                  title="Go to picklynow's Instagram profiles"
                >
                  <FaInstagram size={45} />
                </a>
              </div>
            </div>
          </div>
          <p className={styles.copyright}>
            &copy; {new Date().getFullYear()} Picklynow. All Rights Reserved.
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
