import React, { useState, useEffect } from "react";
import Footer from "../Footer/Footer";
import Spinner from "../../common/Other/Spinner";
import styles from "./SuccessAndCancel.module.css";

const BasicReviewStatus = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        {loading ? (
          <div className={styles.spinnerContainer}>
            <Spinner />
          </div>
        ) : (
          <div className={styles.successContainer}>
            <div className={styles.content}>
              <h1 className={styles.title}>Recommendation status</h1>
              <p className={styles.message}>
                We are preparing a recommendation for you. It usually takes up
                to 24 hours, although it’s often quicker. If you can’t find our
                message in your inbox, please check your spam folder. If you
                encounter any issues, feel free to contact us at{" "}
                <a href="mailto:complaint@picklynow.com">
                  complaint@picklynow.com
                </a>
                .
              </p>
              <br />
              <a href="/" className={styles.homeLink}>
                Return to Home
              </a>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
};

export default BasicReviewStatus;
