import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import logo from "../../../assets/images/logoBlack.webp";
import styles from "./NavBar.module.css";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const renderLink = (to, label) => {
    if (location.pathname === "/") {
      return (
        <ScrollLink
          to={to}
          smooth={true}
          duration={500}
          offset={-70}
          onClick={toggleMenu}
        >
          {label}
        </ScrollLink>
      );
    } else {
      return (
        <Link to={`/#${to}`} onClick={toggleMenu}>
          {label}
        </Link>
      );
    }
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.logo}>
        <Link to="/" onClick={() => window.scrollTo(0, 0)}>
          <img src={logo} alt="logo" className="logo" />
        </Link>
      </div>

      <div className={styles.hamburger} onClick={toggleMenu}>
        <div className={`${styles.line} ${isOpen ? styles.open : ""}`}></div>
        <div className={`${styles.line} ${isOpen ? styles.open : ""}`}></div>
        <div className={`${styles.line} ${isOpen ? styles.open : ""}`}></div>
      </div>
      <ul className={`${styles.menu} ${isOpen ? styles.open : ""}`}>
        <li
          onClick={() => {
            toggleMenu();
            window.scrollTo(0, 0);
          }}
        >
          <Link to="/">Homepage</Link>
        </li>
        <li>{renderLink("about", "About Us")}</li>
        <li>{renderLink("pricing", "Pricing")}</li>
        <li>{renderLink("steps", "Steps")}</li>
        <li>{renderLink("opinions", "Opinions")}</li>
        <li>{renderLink("faq", "FAQ")}</li>
        <li>
          <Link to={`/partner`} className={styles.partner} onClick={toggleMenu}>Partner</Link>
        </li>
        <li>{renderLink("contact", "Contact")}</li>
        <li>{renderLink("blog", "Blog")}</li>
      </ul>
    </nav>
  );
};

export default NavBar;
