import React from "react";
import { Link } from "react-router-dom";
import styles from "./AboutUs.module.css";

const AboutUs = ({ webPilotAPI }) => {
  return (
    <>
      <section className={styles.aboutUsSection}>
        <div className={styles.container}>
          <h2 className={styles.title}>About Us</h2>
          <p className={styles.description}>
            We are a team of tech enthusiasts passionate about innovation,
            dedicated to making advanced technology accessible for everyone. Our
            mission is to provide you with the insights you need to make
            confident, informed decisions when choosing the latest gadgets.
          </p>
          <div className={styles.highlightsContainer}>
            <div className={styles.highlight}>
              <h3 className={styles.highlightTitle}>Tech Enthusiasts</h3>
              <p className={styles.highlightDescription}>
                We love exploring new devices, analyzing their features, and
                sharing our insights with you.
              </p>
            </div>
            <div className={styles.highlight}>
              <h3 className={styles.highlightTitle}>Fast & Reliable</h3>
              <p className={styles.highlightDescription}>
                Our platform ensures quick and accurate recommendations
                delivered straight to your inbox.
              </p>
            </div>
            <div className={styles.highlight}>
              <h3 className={styles.highlightTitle}>Affordable</h3>
              <p className={styles.highlightDescription}>
                For free, get a detailed, personalized recommendation crafted
                specifically to suit your needs.{" "}
                {webPilotAPI && (
                  <span>You can also choose the extended version for $5.</span>
                )}
              </p>
            </div>
          </div>
          <p className={styles.footerText}>
            Together, we’re here to simplify your tech shopping experience. Your
            satisfaction drives our passion for innovation!
          </p>
          {/* <Link to={`/partner`}><button className={styles.ctaButton}>Become a Partner</button></Link> */}
        </div>
      </section>
    </>
  );
};

export default AboutUs;
