import React, { useState } from "react";
import * as Sentry from "@sentry/react";
import DOMPurify from "dompurify";
import axios from "axios";
import { useAppState } from "../../../appState/AppStateContext";
import Spinner from "../../common/Other/Spinner";
import ErrorMessage from "../../common/Message/ErrorMessage";
import SuccessMessage from "../../common/Message/SuccessMessage";
import termsAndConditions from "../../../assets/files/terms_and_conditions_pickly_2024.pdf";
import privacyPolicy from "../../../assets/files/privacy_policy_pickly_2024.pdf";
import cookiesPolicy from "../../../assets/files/cookies_policy_pickly_2024.pdf";
import aiPolicy from "../../../assets/files/ai_policy_pickly_2024.pdf";
import styles from "./Contact.module.css";

const Contact = ({ reportError }) => {
  const { state } = useAppState();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    const sanitizedMessage = DOMPurify.sanitize(formData.message);

    const sanitizedFormData = { ...formData, message: sanitizedMessage };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/contact/create`,
        sanitizedFormData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": state.csrfToken,
          },
          withCredentials: true,
        }
      );

      if (response.status === 201) {
        setSuccess(true);
        setFormData({
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          message: "",
        });
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.status);
      } else {
        setError("An error occurred. Please try again later.");
      }
      Sentry.captureException(error, {
        extra: {
          message: "Error while creating new contact",
          component: "Contact",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className={styles.contactSection}>
        <div className={styles.container}>
          <h2 className={styles.title}>Contact Us</h2>
          {error && <ErrorMessage statusCode={error} />}
          {success && (
            <SuccessMessage message="The contact form has been successfully submitted." />
          )}
          {reportError ? (
            <form className={styles.contactForm} onSubmit={handleSubmit}>
              <div className={styles.row}>
                {/* Left Column */}
                <div className={styles.column}>
                  <div className={styles.inputGroup}>
                    <div className={styles.labelContainer}>
                      <label className={styles.label} htmlFor="firstName">
                        First Name:
                      </label>
                    </div>
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      className={styles.input}
                      required
                    />
                  </div>
                  <div className={styles.inputGroup}>
                    <div className={styles.labelContainer}>
                      <label className={styles.label} htmlFor="lastName">
                        Last Name:
                      </label>
                    </div>
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      className={styles.input}
                      required
                    />
                  </div>
                  <div className={styles.info}>
                    <p>
                      Below you will find important documents:
                      <ul className={styles.list}>
                        <li>
                          <a
                            href={termsAndConditions}
                            download="terms_and_conditions_pickly_2024.pdf"
                            className={`${styles.link} ${styles.infoLink}`}
                          >
                            Terms and Conditions Policy
                          </a>
                        </li>
                        <li>
                          <a
                            href={privacyPolicy}
                            download="privacy_policy_ikorni_2024.pdf"
                            className={`${styles.link} ${styles.infoLink}`}
                          >
                            Privacy Policy
                          </a>
                        </li>
                        <li>
                          <a
                            href={cookiesPolicy}
                            download="cookies_policy_pickly_2024.pdf"
                            className={`${styles.link} ${styles.infoLink}`}
                          >
                            Cookies Policy
                          </a>
                        </li>
                        <li>
                          <a
                            href={aiPolicy}
                            download="ai_policy_pickly_2024.pdf"
                            className={`${styles.link} ${styles.infoLink}`}
                          >
                            AI Policy
                          </a>
                        </li>
                      </ul>
                      If you have a question,{" "}
                      <a href="mailto:office@picklynow.com">write to us!</a>
                    </p>
                  </div>
                </div>

                {/* Right Column */}
                <div className={styles.column}>
                  <div className={styles.inputGroup}>
                    <div className={styles.labelContainer}>
                      <label className={styles.label} htmlFor="phone">
                        Phone:
                      </label>
                    </div>
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      className={styles.input}
                      required
                    />
                  </div>
                  <div className={styles.inputGroup}>
                    <div className={styles.labelContainer}>
                      <label className={styles.label} htmlFor="email">
                        Email:
                      </label>
                    </div>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={formData.email}
                      onChange={handleChange}
                      className={styles.input}
                      required
                    />
                  </div>
                  <div className={styles.inputGroup}>
                    <div className={styles.labelContainer}>
                      <label className={styles.label} htmlFor="message">
                        Your Message:
                      </label>
                    </div>
                    <textarea
                      name="message"
                      id="message"
                      value={formData.message}
                      onChange={handleChange}
                      className={styles.textarea}
                      required
                    ></textarea>
                  </div>
                  <div className={styles.buttonContainer}>
                    <button type="submit" className={styles.button}>
                      {loading ? <Spinner /> : <span>Write Us a Message</span>}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <div className={styles.contactMaintenance}>
              If you have a question,{" "}
              <a href="mailto:office@picklynow.com"> write to us!</a>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Contact;
