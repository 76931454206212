import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { useParams } from "react-router-dom";
import Spinner from "../../common/Other/Spinner";
import Footer from "../Footer/Footer";
import styles from "./Survey.module.css";

const Survey = () => {
  const { rating, survey } = useParams();
  const [ratingParam, setRatingParam] = useState("");
  const [surveyParam, setSurveyParam] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (rating && survey) {
      setRatingParam(rating);
      setSurveyParam(survey);
    }
  }, [rating, survey]);

  useEffect(() => {
    const submitRating = async () => {
      if (ratingParam && surveyParam) {
        try {
          await axios.post(`${process.env.REACT_APP_API_URL}/survey/create`, {
            ratingParam,
            surveyParam,
          });
        } catch (error) {
          Sentry.captureException(error, {
            extra: {
              message: "Error while submitting rating",
              component: "Survey",
            },
          });
        }
      }
    };

    if (ratingParam && surveyParam) {
      submitRating();
    }
  }, [ratingParam, surveyParam]);

  if (isLoading) {
    return (
      <div className={styles.spinnerContainer}>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div>
        <div className={styles.surveyContainer}>
          <div className={styles.content}>
            <h1 className={styles.title}>We appreciate your feedback!</h1>
            <p className={styles.message}>
              Your rating has been successfully submitted.
            </p>
            <a href="/" className={styles.homeLink}>
              Return to Home
            </a>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Survey;
