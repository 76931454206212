import React from "react";
import { Link as ScrollLink } from "react-scroll";
import styles from "./Pricing.module.css";

const Pricing = ({ webPilotAPI }) => {
  return (
    <>
      <section className={styles.pricingSection}>
        <div className={styles.container}>
          <h2 className={styles.title}>Pricing</h2>
          <p className={styles.description}>
            For free, get a personalized recommendation to guide your tech
            purchase decisions. Tailored to your needs, preferences, and budget,
            our expert advice helps you choose the right gadgets, software, and
            accessories with confidence.{" "}
            {webPilotAPI && (
              <span>
                {" "}
                Upgrade to the extended report for $5, featuring sentiment
                analysis and insights from real users of your chosen device.{" "}
              </span>
            )}
          </p>
          <div className={styles.benefitsContainer}>
            <div className={styles.benefit}>
              Get your recommendation straight to your inbox in no time.
            </div>
            <div className={styles.benefit}>
              Receive key pros, cons, and user reviews for informed decisions.
            </div>
            <div className={styles.benefit}>
              Avoid buyer's remorse with precise recommendations.
            </div>
            <div className={styles.benefit}>Get valuable advice for free.</div>
          </div>

          <div className={styles.ctaContainer}>
            <ScrollLink to="home" smooth={true} duration={500}>
              <button className={styles.ctaButton}>
                Get Your Recommendation
              </button>
            </ScrollLink>
          </div>
        </div>
      </section>
    </>
  );
};

export default Pricing;
